const firebaseConfig = {
    apiKey: "AIzaSyDFqVt8UPijdpiXes0MByhgjb4oHHuaw8U",
    authDomain: "mission-top-5.firebaseapp.com",
    projectId: "mission-top-5",
    storageBucket: "mission-top-5.appspot.com",
    messagingSenderId: "475489429088",
    appId: "1:475489429088:web:54f09f748e839554a44505",
    measurementId: "G-JNBMG333MC"
};

export default firebaseConfig